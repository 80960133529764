/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import nexo from '../../nexoClient';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Tag,
  Text,
  Title,
  Modal,
  IconButton,
  Icon,
  Link,
  Tooltip,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axiosInstance from '../../axios';
import { useWindowWidth } from '../../hooks';
import { CogIcon, QuestionCircleIcon } from '@nimbus-ds/icons';

const ConfigPage: React.FC = () => {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');
  const { addToast } = useToast();

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const goToLogs = () => push('/logs');
  const goToSetup = () => push('/setup');

  useEffect(() => {
    //getConfig();
  }, []);

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        source={<QuestionCircleIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button appearance="primary">
          <Link
            as="a"
            href="https://scribehow.com/page/Siigo_APP__1nUKzrNARnyp49IRtLHUqQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            EXAMPLE
          </Link>
        </Button>
      </Box>
    );

  return (
    <Page maxWidth="1300px">
      <Page.Header title="Configuraciones" buttonStack={actions} />
      <Page.Body>
        <Layout columns="1" gap="4">
          <Layout columns="3">
            <Layout.Section>
              <Card>
                <Box></Box>
              </Card>
            </Layout.Section>
          </Layout>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default ConfigPage;
