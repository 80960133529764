import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';

const headers = [
  'Id',
  'Nombre Tienda',
  'Origen producto',
  'Tipo oferta',
  'Registro marca',
  'Url',
  'Nombre',
  'Teléfono',
  'Email',
  'Seller Tag',
  'Acciones',
];

import axiosInstance from '../../axios';

const SetupPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVinculate, setIsVinculate] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    usuario_api: '',
    access_key: '',
  });

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    //checkVinculateAccount();
    //getListOfPendingSellers();
    //getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  /*
  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();
  */

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  return (
    <Page maxWidth="1000px" marginTop="18">
      {!isVerifing && isVinculate ? (
        <>
          <Page.Header alignItems="center" title="" />
          <Box display="flex" gap="4" justifyContent="center"></Box>
        </>
      ) : (
        <>
          <Page.Header alignItems="center" title="" />
          <Page.Body>
            <Box></Box>
          </Page.Body>
        </>
      )}
    </Page>
  );
};

export default SetupPage;
